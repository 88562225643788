<template>
  <div class="plan-list">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        抽题系统
      </div>
      <div class="right"></div>
    </div>
    <div class="bind-block">
      <div class="bind-container">
        <div class="bind">
          <div class="bind-item">
            <label class="bind-label">课程：</label>
            <span>{{ detailVO ? detailVO.courseName : "" }}</span>
          </div>
          <div class="bind-item">
            <label class="bind-label">学科学段：</label>
            <span>{{ detailVO ? detailVO.key : "" }}</span>
          </div>
        </div>

        <div class="line"></div>
      </div>
    </div>
    <div class="task-block">
      <div class="task-container">
        <div class="task-body">
          <img class="preview-img" v-for="(item, index) in previewImageList" :key="index" :src="item" />
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="footer">
      <div class="btn-tool">
        <button class="btn" @click="onAddUserHomeWork">提交作业</button>
        <button class="btn" @click="onCopyUrl">复制pdf链接</button>
        <textarea v-model="detailVO.fileUrl" id="copy-textarea" />
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { homeworkAssignApi } from "@/api/homeworkAssignApi.js";

export default {
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      assignId: 0,
      courseId: 0,
      courseName: "",
      key: "",
      detailVO: {},
      previewImageList: [],
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.assignId = this.$route.query.id;
    } else {
      Dialog.alert({
        message: "缺少必要的参数值。",
      });
      return;
    }

    if (this.$route.query.courseId) {
      this.courseId = this.$route.query.courseId;
    } else {
      Dialog.alert({
        message: "缺少必要的参数值。",
      });
      return;
    }

    if (this.assignId && this.courseId) {
      homeworkAssignApi
        .detail(this.assignId)
        .then((res) => {
          console.log("res", res);
          if (res && res.code == 0 && res.data) {
            this.detailVO = res.data;
            if (this.detailVO && this.detailVO.fileUrl) {
              let url = this.detailVO.fileUrl;
              if (url.startsWith("//")) {
                url = "https://" + url;
              }
              this.detailVO.fileUrl = url;
              // preview pdf
              homeworkAssignApi.pdfPreviewList(this.detailVO.fileKey).then((ret) => {
                console.log("##", ret);
                if (ret.code == 0 && ret.datas) {
                  this.previewImageList = ret.datas;
                }
              });
            }
            console.log("#", res);
          }
        })
        .catch((err) => {});
    }
  },
  methods: {
    onGoBack() {
      this.$router.push({
        path: "/jzms-homework",
        query: {
          courseId: this.courseId,
        },
      });
    },
    onCopyUrl() {
      this.$nextTick(() => {
        const text = document.getElementById("copy-textarea");
        text.select();
        if (document.execCommand("copy")) {
          document.execCommand("copy");
          Dialog.alert({
            message: "已复制文档url，请粘贴到网页浏览器下载",
          });
          return;
        }
      });
    },
    onAddUserHomeWork() {
      this.$router.push({
        path: "/userHomeWork/addEdit",
        query: {
          courseId: this.courseId,
          title: this.detailVO.fileName,
          workText: this.detailVO.fileName,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.plan-list {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 62px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .bind-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .bind-container {
      border-radius: 8px 8px 0px 0px;
      background: #ffffff;
      margin: 14px 0px 0px 0px;
      width: 344px;
      .bind {
        margin: 12px 0px 0px 0px;
        font-size: 14px;
        color: #242424;
        font-weight: 500;
        .bind-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          .bind-label {
            margin: 10px;
          }
          .bind-select {
            margin: 10px;
            padding: 0px 0px 0px 10px;
            background: #efefef;
            border-radius: 8px;
            border-radius: 8px;
            width: 96px;
            height: 30px;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
      .line {
        margin: 10px 10px;
        height: 1px;
        border-bottom: 1px solid rgb(239, 239, 239);
      }
    }
  }
  .task-block {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    //background: #ffffff;
    overflow: hidden;
    .task-container {
      width: 344px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      overflow-y: scroll;
      .task-body {
        flex: 1;
        .preview-img {
          width: 100%;
        }
      }
    }
  }
  .footer {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 68px;
    .btn-tool {
      display: flex;
      flex-direction: row-reverse;
      width: 344px;
      background: #ffffff;
      flex: 1;
      .btn {
        margin: 5px 10px;
        width: 120px;
        height: 36px;
        background: #4d52d1;
        border: none;
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        line-height: 14px;
      }
      #copy-textarea {
        position: absolute;
        left: -99999px;
      }
    }
  }
}
</style>
