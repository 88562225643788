import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const myList = (courseId) => {
  return ajax.get(`${base}/api/itedu/v1/homeworkAssign/myList?courseId=${courseId}`);
};

const detail = (assignId) => {
  return ajax.get(`${base}/api/itedu/v1/homeworkAssign/detail?id=${assignId}`);
};

const assignHomework = (courseId) => {
  return ajax.get(`${base}/api/itedu/v1/homeworkAssign/assignHomework?courseId=${courseId}`);
};

const bindInfo = (courseId) => {
  return ajax.get(`${base}/api/itedu/v1/homeworkBind/bindInfo?courseId=${courseId}`);
};

const bind = (courseId, key) => {
  return ajax.post(`${base}/api/itedu/v1/homeworkBind/bind?courseId=${courseId}&key=${key}`);
};

const phaseAndSubject = () => {
  return ajax.get(`${base}/api/itedu/v1/homeworkAssign/phaseAndSubject`);
};

const pdfPreviewList = (cosKey) => {
  return ajax.get(`${base}/api/itedu/v1/pdf/preview?cosKey=${cosKey}`);
};

export const homeworkAssignApi = {
  myList: myList,
  detail: detail,
  assignHomework: assignHomework,
  bindInfo: bindInfo,
  bind: bind,
  phaseAndSubject: phaseAndSubject,
  pdfPreviewList: pdfPreviewList,
};
